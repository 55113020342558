.container{
    padding: 20px;
}

.container.no-window{
    padding: 10vh;
}

/* 
////////////////////////////////
-----ACCORDING TO HEIGHT---------
//////////////////////////////// */ 

@media only screen and (max-height: 700px) and (min-width: 200px)  {
    .container{
        padding: 10px;
    }
    .grid-wrapper{
        gap: 10px;
    }
    .wrapper{
        gap: 10px;
        padding: 10px;
    }
    .container.no-window{
        padding: 40px;
    }
    .wrapper.no-window{
        gap: 0;
        padding: 20px;
    }
    .grid-wrapper.no-window{
        gap:20px;
    }
}

@media only screen and (max-height: 500px) and (min-width: 200px) {
   
    .ReactModal__Content {
        inset: 10px 10px!important;
    }
    .container{padding: 5px;} .container.no-window{
        padding: 10px;
    }
    .grid-wrapper{gap: 5px;} .grid-wrapper.no-window{
        gap: 10px;
    }
    .wrapper{padding: 5px;} .wrapper.no-window{
        padding: 10px;
    }
    
}
@media only screen and (max-height: 330px) {
    .container, .container.no-window{
        padding: 5px;
    }
    .wrapper, .wrapper.no-window{
        padding: 5px;
    }
    .grid-wrapper, .grid-wrapper.no-window{
        gap: 5px;
    }
}
/* 
////////////////////////////////
-----ACCORDING TO WIDTH---------
//////////////////////////////// */


@media only screen and (max-width: 850px) {
    .wrapper, .wrapper.no-window{
        grid-template-columns: 1fr 0fr;
        gap: 0px;
    }
}

@media only screen and (max-width: 600px) and (min-height: 330px){
    .container, .container.no-window{
        padding: 10px;
    }
    .wrapper, .wrapper.no-window{
        padding: 10px;
    }
    .grid-wrapper, .grid-wrapper.no-window{
        gap: 10px;
    }
}

@media only screen and (max-width: 200px) {
    .ReactModal__Content {
        inset: 15px 15px!important;
    }
    .container, .container.no-window{
        padding: 5px;
    }
    .wrapper, .wrapper.no-window{
        padding: 5px;
    }
    .grid-wrapper, .grid-wrapper.no-window{
        gap: 5px;
    }
}