.cp-wrapper {
    text-align: left;
}

.platform-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.total-ques-wrapper {
    grid-column: 1/5;
}

.total-ques {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.total-ques>div {
    border-bottom: 1px solid black;
}

.elem {
    text-align: center;
}

.elem * {
    margin-top: 5px;
}

.perf-wrapper {
    grid-column: 1/5;
}

.perf-wrapper>ul {
    line-height: 1.2rem;
    margin-top: 10px;
    margin-left: 4rem;
}

.perf-wrapper>table,
.total-ques-wrapper table {
    line-height: 1.2rem;
    margin-top: 10px;
    /* margin: 0 4rem; */
    /* margin-left: 4rem; */
}


.perf-wrapper table thead, 
.total-ques-wrapper table thead {
    background: rgba(0, 0, 0, 0.05);
}

.perf-wrapper table tr:nth-child(even),
.total-ques-wrapper table tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
}

.perf-wrapper td,
.total-ques-wrapper td,
.perf-wrapper th,
.total-ques-wrapper th {
    padding: 10px;
}
.show-on-vsmall{
    display: none;
}
@media only screen and (max-width: 500px) {
    .show-on-vsmall{
        display: inline;
    }
    .hide-on-vsmall{
        display: none;
    }

}

@media only screen and (max-width: 400px) {
    .platform-wrapper{
        gap: 10px;
    }
    .platform-wrapper .total-ques-wrapper, .platform-wrapper .perf-wrapper {
        padding: 10px!important;
    }
    .perf-wrapper td, .total-ques-wrapper td, .perf-wrapper th, .total-ques-wrapper th {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }

}

@media only screen and (max-width: 1000px) {
    .platform-wrapper {
        grid-template-columns: 1fr 1fr;
    }
    .total-ques-wrapper {
        grid-column: 1/3;
    }
    .perf-wrapper {
        grid-column: 1/3;
    }
}
