* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: 0.2s; */
}
a.no-decoration {
  text-decoration: none;
  color: inherit;
  display: contents;
} 
a.link {
  color: #0366d6;
  text-decoration: none;
}


#app {
  width: 100%;
  height: 100vh;
}

body {
  background-color: #A3BFFA;
  color: white;
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  /* padding-top: 2rem; */
  line-height: inherit;
}