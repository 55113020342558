* {
    /* line-height: 1.2em; */
}

.open-source-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    text-align: left;
}

.github-wrapper {
    display: grid;
    grid-template-columns: 0fr 1fr;
    grid-gap: 1rem;
}

.github-profile img {
    width: 50%;
}

.github-profile .table {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.github-stats img {
    width: 100%;
    border-radius: 1em;
}

.contribtions {
    text-align: left;
}

.orgs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
}

.org {
    text-align: center;
    border: 1px dashed rgba(0, 0, 0, 0.438);
    border-radius: 1em;
    padding: 1em;
    display: grid;
    grid-template-rows: 1fr 0fr 0fr;
}

.org .img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pos {
    color: green;
}

.neg {
    color: red;
}

.org>.stats {
    padding-top: 20px;
}

.contribtions h2 {
    margin-bottom: 1rem;
}

.org img {
    width: 50%;
}

.banner .tag {
    border: 1px dashed rgba(0, 0, 0, 0.438);
    display: inline-block;
    padding: 0.1rem 1em;
    border-radius: 2em;
    line-height: 2em;
    margin-left: 1em;
}

.banner li {
    display: grid;
    grid-template-columns: 0fr 1fr 0fr;
    background-color: rgba(97, 97, 97, 0.082);
    border-radius: 0.5em;
    border: 1px dashed rgba(0, 0, 0, 0.438);
    margin-bottom: 1em;
    grid-gap: 1em;
    padding: 1em;
    cursor: pointer;
}

.banner .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 1em; */
}

.banner .link {
    cursor: pointer;
}

.banner li:hover {
    background-color: rgba(97, 97, 97, 0.2);
}

.icon.type.open {
    color: green
}

.icon.type.merged {
    color: rgb(133, 86, 245);
}

.icon.type.closed {
    color: red;
}

.gsoc {
    display: grid;
    grid-template-columns: 0fr 1fr;
}

.gsoc-img img {
    width: 6rem;
}

.gsoc-img {
    height: 100%;
}

.gsoc-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gsoc-cnt h2 {
    text-align: center;
    line-height: 1em;
}

.gsoc p {
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .orgs {
        grid-template-columns: 1fr 1fr;
    }
    .github-profile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        text-align: center;
    }
    .github-profile img {
        width: 100%;
    }
    .github-wrapper {
        grid-template-columns: 1fr 0fr;
        grid-gap: 0rem;
    }
    .github-cnt {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) {
    .org img {
        width: 100%;
    }
    .open-source-wrapper h2 {
        line-height: 1.1em;
    }
    .open-source-wrapper .orgs {
        gap: 15px;
    }
    .open-source-wrapper>div {
        padding: 15px!important;
    }
    .open-source-wrapper ul {
        padding: 0!important;
        padding-top: 15px!important;
    }
    .open-source-wrapper li {
        padding: 10px;
        gap: 10px;
    }
}



@media only screen and (max-width: 450px) {
    .open-source-wrapper .orgs {
        gap: 15px;
    }
    .open-source-wrapper>div {
        padding: 15px!important;
    }
    .open-source-wrapper ul {
        padding: 0!important;
        padding-top: 15px!important;
    }
}