.field-wrapper{
    display: inline-grid;
    grid-template-columns: 1fr 10fr;
    gap: 10px
}
.contact-box{
    max-width: 500px;
    width: 100%;
}

.field-wrapper textarea{
    width: 100%;
    height: 100px;
}

.field-wrapper .right, .field-wrapper .left{
    padding: 0 10px;
    text-align: left;

}

.field-wrapper textarea, 
.field-wrapper input{
    /* color: red */
    padding: 5px;
    width: 100%;
}

.field-wrapper textarea:focus, 
.field-wrapper input:focus {
    outline: 1px solid rgba(125,195,242);
}

.field-wrapper .button{
    grid-column: 1/3;
}

.field-wrapper .message{
    text-align: center;
    grid-column: 1/3;
}
.field-wrapper .message.ok{
    color: green
}
.field-wrapper .message.err{
    color: red
}
.field-wrapper .disable{
    background-color: rgba(128, 128, 128, 0.468)!important;
    background-image: none!important;
    border-color: gray!important;
    pointer-events: none;
}
.contact-wrapper .social{
    margin-top: 10px;
}
.contact-wrapper .icon{
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}
.contact-wrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#reCaptcha{
    display: flex;
    grid-column: 1/3;
    justify-content: center;
    align-items: center;
}
.rc-anchor-error-msg-container{

}


@media only screen and (max-width: 850px) {
    .field-wrapper .right{
        padding-top: 0px;
        padding-bottom: 1   0px;

    }
    .field-wrapper .left{
        padding-bottom: 0px;
    }
    .contact-wrapper .field-wrapper{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .field-wrapper .button, #reCaptcha, .field-wrapper .message{
        grid-column: 1/2;
    }
}
