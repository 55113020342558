.w404 {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.w404 .wrapper404 {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 1em;
    animation: entrance 0.5s ease-out normal backwards!important;
    margin: 20px;
}

.w404 .wrapper404 .tile404 {
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    max-width: max-content;
    display: grid;
    grid-template-rows: 1fr 0fr 0fr;
    padding: 50px;
    animation: breathing 1s ease-in-out normal backwards infinite;
    color: rgba(0, 0, 0, 0.726);
    text-align: center;
}

.w404 .tile404 .icon {
    font-size: 200px;
}

.w404 .tile404 .title {
    font-size: 100px;
    line-height: 100px;
    margin: 5px;
}

.w404 .tile404 .sub-title {
    font-size: 50px;
    line-height: 50px;
}

.w404 .tile404 .content {
    font-size: 20px;
    line-height: 20px;
    margin: 10px;
}

@media only screen and (max-width: 500px) {
    .w404 .wrapper404 .tile404 {
        padding: 20px;
    }
    .w404 .tile404 .icon {
        font-size: 50vw;
    }
    .w404 .tile404 .title {
        font-size: 20vw;
        line-height: 20vw;
    }
    .w404 .tile404 .sub-title {
        font-size: 30px;
        line-height: 30px;
    }
    .w404 .tile404 .content {
        font-size: 18px;
        line-height: 18px;
        margin: 10px;
    }
}