.grid-wrapper{
    grid-template-areas: 
        'im  hd  hd  hd'
        'ab  pr  pr  sk'
        'ed  ed  ex  ex'
        'ac  cr  ex  ex'
        'cp  cp  op  op' 
        'ec  re  so  co'
    ;
    grid-template-columns: repeat(4,minmax(0, 1fr));
    grid-template-rows: repeat(6,minmax(0, 1fr));
}


@media only screen and (min-width: 800px) {
    .grid-wrapper.no-window{
        grid-template-areas: 
        'ab  sk  im  im cp cp'
        'ex  ex  im  im op op'
        'pr  ed  im  im cr so'
        'ac  ec  hd  hd re co';
        grid-template-columns: repeat(6,minmax(0, 1fr));
        grid-template-rows: repeat(4,minmax(0, 1fr));
    };
}

@media only screen and (max-width: 500px) {
    .grid-wrapper{
        grid-template-areas: 
        'im  hd  hd'
        'ab  pr  pr'
        'ed  ed  sk'
        'cp  cp  ex' 
        'op  op  ex'
        'ac  re  cr'
        'ec  co  so';
        grid-template-columns: repeat(3,minmax(0, 1fr));
        grid-template-rows: repeat(7,minmax(0, 1fr));
    }
    
}

@media only screen and (max-height: 400px) {
    .grid-wrapper, .grid-wrapper.no-window{
        grid-template-areas: 
            'im  hd  hd  hd hd re'
            'ab  ac  pr  pr sk so'
            'ed  ac  ex  ex op so'
            'cr  ec  ex  ex cp co';
        grid-template-columns: repeat(6,minmax(0, 1fr));
        grid-template-rows: repeat(4,minmax(0, 1fr));
    }
}

@media only screen and (max-width: 370px) {
    .grid-wrapper {
        grid-template-areas: 'hd  hd' 'im  ab' 'im  pr' 'ex  ex' 'cp  op' 'ed  sk' 'ac  ec' 're  cr' 'co  so';
        grid-template-columns: repeat(2,minmax(0, 1fr));
        grid-template-rows: repeat(9,minmax(0, 1fr));
    }
}

@media only screen and (max-width: 270px) {
    .grid-wrapper {
        grid-template-areas: 'im' 'hd' 'ab '' pr' 'ed '' sk' 'ex '' ex' 'cp '' op' 'ac '' ec' 're '' cr' 'co '' so';
        grid-template-columns: repeat(1,minmax(0, 1fr));
        grid-template-rows: repeat(15,minmax(0, 1fr));
        min-width: auto;
        min-height: auto;
    }
}