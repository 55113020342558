.nothing{
    width: auto;
}
.shadow {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.185);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.185);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.185);
}
.container{
    height: 100vh;
    width: 100vw;
    transition: 200ms;
}


.wrapper{
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    display: grid;
    gap: 20px;
    overflow: auto;
    border-radius: 1rem;
}

.wrapper {
    grid-template-columns: max-content 1fr;
}

.wrapper.no-window {
    gap: 0px;
}

.layout-wrapper {
    width: auto;
    height: 100%;
}

.window-wrapper {
    overflow: hidden;
}

.social-wrapper .tile-icon{
    display: contents;
}

/* No window Opened */

@media only screen and (min-width: 1000px) {
    .wrapper.no-window {
        grid-template-columns: 1fr 0fr;
        /* margin: 10vh;
        height: 80vh;
        gap: 0; */
    }
}

/* --- */

@media only screen and (max-width: 1000px) {
    .wrapper {
        grid-template-columns: 1fr 1fr;
    }
    .wrapper.no-window {
        grid-template-columns: 1fr 0fr;
    }
}

@media only screen and (max-width: 850px) {
    /* .wrapper {
        padding: 20px;
        height: 92vh;
        grid-template-columns: 1fr 0fr;
        gap: 0!important;
    } */
    .window-wrapper {
        display: none;
    }
}