.vertical-timeline {
    width: 60%;
}

.inner p{
    margin: 0;
}
.inner {
    grid-template-columns: 1fr 0fr;
    display: grid
}
.inner .img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.inner img{
    width: 100px;
}

@media only screen and (max-width: 1200px) {
    .vertical-timeline {
        width: 100%;
    }
}