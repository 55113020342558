.project{
    line-height: 1.5em;
    display: grid;
    grid-template-rows: 0fr 0fr 0fr 0fr;
    gap: 10px
}
.projects-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.project img{
    max-width: 100%;
    max-height: 20vh;
}

.project hr{
    border: 1px dashed rgba(128, 128, 128, 0.281);
    /* margin: 0.8em 0; */
}

.project .content{
    text-align: left;
    display: grid;
    gap: 0.5em
}
.project ul{
    padding-inline-start: 40px;
}
.project .badges{
    text-align: center;

}

.project .badges span{
    border: 1px solid rgba(0, 0, 0, 0.109);
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 0.5em;
    display: inline-block;
    margin-bottom: 0.5em;
    line-height: 1.5em;
}

@media only screen and (max-width: 1200px) {
    .projects-wrapper{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }
}