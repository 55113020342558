.about-me-wrapper {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-areas: 
    'me-ab me-ab me-ab' 
    'me-lo me-la me-im' 
    'me-we me-pr me-im'
    'me-co me-co me-im'
    ;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 20px
}
.side-by-side{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    text-align: center;
}
.about-me-wrapper * {
    line-height: 1.5em;
}

.about-me-wrapper .about-box {
    display: grid;
    grid-template-columns: 0fr 1fr;
    grid-column: 1/3;
}

.about-me-wrapper .span {
    grid-column: 1/3;
}

.about-me-wrapper .icon {
    /* font-size: 50px; */
    margin: 0;
    padding: 0;
    line-height: 0em;
}
.about-me-wrapper .link:hover, .link:active, .link:focus {
    background-color: rgba(0, 0, 0, 0.09)!important;
    cursor: pointer!important;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.185);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.185);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.205);
}
.box{
    padding: 10px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me-wrapper img{
    object-fit: cover;
    width: 100%;
}
.about-me-wrapper .img{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.about-me-wrapper .icon{
    font-size: 25px;
}
.about-me-wrapper .box-title{
    vertical-align: super;
    margin: 10px;
}
.about-me-wrapper .show-on-vsmall{
    display: none
}
@media only screen and (max-width: 1440px) {
    .about-me-wrapper {
        grid-template-areas: 
        'me-ab me-ab me-ab' 
        'me-lo me-la me-im' 
        'me-we me-pr me-im'
        'me-co me-co me-co'
        ;
    }
}
@media only screen and (max-width: 1200px) {
    .about-me-wrapper {
        grid-template-areas: 
        'me-ab me-ab' 
        'me-lo me-im' 
        'me-la me-im' 
        'me-we me-we'
        'me-pr me-pr'
        'me-co me-co';
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 1100px) {
    .about-me-wrapper {
        grid-template-areas: 
        'me-ab me-ab' 
        'me-lo me-im' 
        'me-la me-im' 
        'me-we me-we'
        'me-pr me-pr'
        'me-co me-co';
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 850px) {
    .about-me-wrapper {
        grid-template-areas: 
        'me-ab me-ab' 
        'me-lo me-im' 
        'me-la me-im' 
        'me-we me-im'
        'me-pr me-im'
        'me-co me-co';
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 600px) {
    .about-me-wrapper .show-on-vsmall{
        display: block;
    }
    .about-me-wrapper .hide-on-vsmall{
        display: none;
    }
    .about-me-wrapper {
        grid-template-areas: 
        'me-ab' 
        'me-im'
        'me-lo'
        'me-la' 
        'me-we' 
        'me-pr'
        'me-co ';
        grid-template-columns: 1fr;
    }
    .about-me-wrapper img{
        object-fit: cover;
        width: 200px;
    }
}