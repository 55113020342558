.achivement-wrapper{
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1em;
    height: 100%;
}
.cert-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
.extracurricular-wrapper{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    height: 100%;
}

.cert-wrapper p{
    margin-bottom: 5px ;
}
.photo-grid-wrapper .box{
    display: flex;
    flex-direction: column;
}

.photo-grid-wrapper .box .img{
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.photo-grid-wrapper .title{
    padding: 10px 0;
    line-height: initial;
}



@media only screen and (max-width: 850px) {
    .achivement-wrapper{
        grid-template-columns: 1fr;
    }
    .cert-wrapper{
        grid-template-columns: 1fr;
    }
    .extracurricular-wrapper{
        grid-template-columns: 1fr;
    }
}