.cnt {
    text-align: center;
    width: 100%;
    /* height: auto; */
    /* overflow: hidden; */
    overflow: auto;
    /* flex: 1; */
    height: 100%;
    padding: 1em;
}

.cnt h1 {
    margin: 0;
}

.timelineElem {
    text-align: left;
    padding: 15px;
}

.timelineElem li {
    margin: 10px;
    line-height: 20px;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
        left: auto;
        right: 144%;
        text-align: right;
    }
}

.window-wrapper > div:nth-child(2) {
    overflow: hidden;
    padding: 0!important;
}